import { z } from 'zod';

const REQUIRED_MESSAGE = 'Please type something';

const RequiredString = z
  .string({ required_error: REQUIRED_MESSAGE })
  .min(1, { message: REQUIRED_MESSAGE });

const OptionalString = z.optional(
  z
    .string()
    .nullable()
    .transform((value) => (value === null ? undefined : value))
);
const OptionalNumber = z.optional(
  z
    .number()
    .nullable()
    .transform((value) => (value === null ? undefined : value))
);

export { RequiredString, OptionalString, OptionalNumber };
